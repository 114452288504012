import styled from 'styled-components';
import { Box, Button } from '@material-ui/core';

import theme from '../../styles/themes';

export const ImageContainer = styled(Box)`
  height: 300px;
  width: 85%;
  margin: auto auto -30px;
  border-radius: 16px;
  overflow: hidden;
  z-index: 1;
  ${theme.breakpoints.up('md')} {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 120%;
    width: 500px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 625px;
  }
`;

export const InfoContainer = styled(Box)`
  > div {
    padding: 60px 30px 30px 30px;
  }
  ${theme.breakpoints.up('md')} {
    max-width: 600px;
    > div {
      padding: 40px 160px 40px 40px;
    }
  }
  ${theme.breakpoints.up('lg')} {
    max-width: 660px;
    > div {
      padding: 40px 120px 40px 60px;
    }
  }
`;

export const ExploreButton = styled(Button)`
  background: linear-gradient(
    97.31deg,
    #1a91ff 2.63%,
    #5c00f2 79.1%,
    #9e24fd 108.63%
  );
  box-shadow: 0px 3px 3px rgba(31, 0, 83, 0.4),
    -2px -3px 11px rgba(0, 111, 213, 0.1), 4px 3px 10px rgba(103, 33, 214, 0.3),
    inset 0px 1px 0px rgba(255, 255, 255, 0.7);
  border-radius: 51px;
  padding: 10px 15px;
  color: #ffffff;

  &:hover {
    opacity: 0.8;
  }
`;
