import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import ContactImage from '../../assets/images/services-contact.png';
import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import { ExploreButton, ImageContainer, InfoContainer } from './styled';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

const Contact = () => {
  return (
    <ContentContainer>
      <Box position="relative" zIndex="1" color="#ffffff">
        <Grid container justify="flex-start">
          <ImageContainer>
            <ResponsiveImage
              style={{ backgroundImage: `url(${ContactImage})` }}
            />
          </ImageContainer>
          <InfoContainer>
            <Box
              bgcolor="#2C2C2C"
              borderRadius="16px"
              boxShadow="0px 8px 6px rgba(0, 0, 0, 0.25)"
            >
              <Typography variant="h3" gutterBottom>
                <FormattedHTMLMessage id="contactComponentTitle" />
              </Typography>
              <Typography variant="body1" style={{ marginBottom: '40px' }}>
                <FormattedHTMLMessage id="contactComponentText" />
              </Typography>
              <ExploreButton>
                <Typography variant="body1" style={{ fontWeight: '500' }}>
                  <FormattedHTMLMessage id="contactComponentButton" />
                </Typography>
              </ExploreButton>
            </Box>
          </InfoContainer>
        </Grid>
      </Box>
    </ContentContainer>
  );
};

export default Contact;
