import React, { useCallback, useRef, useState } from 'react';
import { Box, Hidden, Typography, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { useWindowResize, useDebouncedFn } from 'beautiful-react-hooks';

import CarImage1 from '../../assets/images/about-us-car-1.png';
import CarImage2 from '../../assets/images/about-us-car-2.png';
import CarImageRed from '../../assets/images/about-us-car-red.png';
import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import theme from '../../styles/themes';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

const DimmedText = styled(Typography)`
  color: rgba(147, 162, 174, 0.2);
  font-weight: 900;
  transform: translateY(-50%);
  line-height: 117.19px;
  font-size: 60px;
  letter-spacing: 11.5px;

  ${theme.breakpoints.up('lg')} {
    font-size: 100px;
  }
`;

const ImageContainer = styled(Box)`
  height: 300px;
  margin: auto auto -30px;
  border-radius: 16px;
  overflow: hidden;
  z-index: 1;
  ${theme.breakpoints.up('md')} {
    position: absolute;
    right: 40px;
    bottom: 0;
    margin: auto;
    height: 85%;
  }
  ${theme.breakpoints.up('lg')} {
  }
`;

const OurWay = () => {
  const [redCarWidth, setRedCarWidth] = useState('500px');
  const redCarRef = useRef(null);
  const redCarCallback = useCallback((node) => {
    if (node !== null) {
      setRedCarWidth(
        node.getBoundingClientRect().left + node.getBoundingClientRect().width
      );
      redCarRef.current = node;
    }
  }, []);
  const handleWindowResize = useDebouncedFn(() => {
    if (redCarRef.current !== null) {
      setRedCarWidth(
        redCarRef.current.getBoundingClientRect().left +
          redCarRef.current.getBoundingClientRect().width
      );
    }
  }, 100);
  useWindowResize(handleWindowResize);

  return (
    <Box bgcolor="#000000" color="#ffffff">
      <ContentContainer>
        <Grid container justify="flex-end">
          <Hidden smDown>
            <Grid item xs={12} md={4} lg={5}>
              <Box position="relative" height="100%" ref={redCarCallback}>
                <ImageContainer width={redCarWidth}>
                  <ResponsiveImage
                    style={{ backgroundImage: `url(${CarImageRed})` }}
                  />
                </ImageContainer>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8} lg={7}>
            <Box position="relative">
              <Typography
                variant="h1"
                style={{
                  zIndex: '1',
                  fontWeight: '500',
                  marginBottom: '10px',
                  position: 'relative',
                }}
              >
                <FormattedHTMLMessage id="aboutWayTitle" />
              </Typography>
              <Hidden smDown>
                <Box position="absolute" top="0" left="0">
                  <DimmedText>
                    <FormattedHTMLMessage id="aboutWayDrop" />
                  </DimmedText>
                </Box>
              </Hidden>
            </Box>
            <Typography variant="body1" style={{ marginBottom: '60px' }}>
              <FormattedHTMLMessage id="aboutWayText" />
            </Typography>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Box display="flex" mb="94px">
                    <Typography
                      variant="subtitle2"
                      style={{ color: '#6B6B6B', marginRight: '30px' }}
                    >
                      01
                    </Typography>
                    <Typography variant="body1">
                      <FormattedHTMLMessage id="aboutWay1" />
                    </Typography>
                  </Box>
                  <img src={CarImage1} alt="about us car x ray" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Box display="flex" mb="94px">
                    <Typography
                      variant="subtitle2"
                      style={{ color: '#6B6B6B', marginRight: '30px' }}
                    >
                      02
                    </Typography>
                    <Typography variant="body1">
                      <FormattedHTMLMessage id="aboutWay2" />
                    </Typography>
                  </Box>
                  <img src={CarImage2} alt="about us car x ray" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContentContainer>
    </Box>
  );
};

export default OurWay;
