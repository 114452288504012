import Member from './Member';

export interface IMemberProps {
  name: string;
  position: string;
  image: {
    url: string;
  };
  alt: string;
}

export default Member;
