import React from 'react';

import SEO from '../components/SEO';
import Layout from '../layout/AppLayout';
import { OurWay, OurStory, OurTeam } from '../screens/AboutUs';
import Contact from '../components/Contact';

const AboutUs = () => {
  return (
    <Layout>
      <SEO title="About Us" />
      <OurWay />
      <OurStory />
      <OurTeam />
      <Contact />
    </Layout>
  );
};

export default AboutUs;
