import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

import MapImage from '../../assets/images/about-us-our-story.svg';
import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import theme from '../../styles/themes';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

const TextContainer = styled(Box)`
  width: 100%;
  position: relative;

  ${theme.breakpoints.up('md')} {
    width: 620px;
  }
`;

const ImageContainer = styled(Box)`
  position: absolute;
  top: 43px;
  right: 0;
  height: 629px;
  width: 100%;
  z-index: 0;

  ${theme.breakpoints.up('sm')} {
    width: 615px;
  }
`;

const Content = styled(ContentContainer)`
  position: relative;
  ${theme.breakpoints.up('lg')} {
    padding: 80px 0 174px;
  }
`;

const OurStory = () => {
  return (
    <Box bgcolor="#1C1534" color="#ffffff">
      <Content>
        <ImageContainer>
          <ResponsiveImage style={{ backgroundImage: `url(${MapImage})` }} />
        </ImageContainer>
        <Grid container justify="flex-start">
          <TextContainer>
            <Typography
              style={{
                fontSize: '46px',
                fontWeight: '500',
                marginBottom: '10px',
                lineHeight: '50.23px',
              }}
            >
              <FormattedHTMLMessage id="aboutStoryTitle" />
            </Typography>
            <Typography
              variant="body1"
              style={{
                marginBottom: '60px',
                letterSpacing: '1px',
                maxWidth: '480px',
                lineHeight: '21.09px',
              }}
            >
              <FormattedHTMLMessage id="aboutStorySubtitle" />
            </Typography>
            <Typography
              variant="body1"
              style={{
                marginBottom: '60px',
                letterSpacing: '1px',
                lineHeight: '21.09px',
              }}
            >
              <FormattedHTMLMessage id="aboutStoryText" />
            </Typography>
            <Grid container spacing={7} style={{ minHeight: '275px' }}>
              {/*<Grid item xs={12} md={6}>
                <Typography
                  variant="h3"
                  style={{
                    marginBottom: '20px',
                    lineHeight: '28.13px',
                    letterSpacing: '1px',
                  }}
                >
                  <FormattedHTMLMessage id="aboutStory1Title" />
                </Typography>
                <Typography
                  variant="body1"
                  style={{ lineHeight: '21.09px', letterSpacing: '1px' }}
                >
                  <FormattedHTMLMessage id="aboutStory1Text" />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h3"
                  style={{
                    marginBottom: '20px',
                    lineHeight: '28.13px',
                    letterSpacing: '1px',
                  }}
                >
                  <FormattedHTMLMessage id="aboutStory2Title" />
                </Typography>
                <Typography
                  variant="body1"
                  style={{ lineHeight: '21.09px', letterSpacing: '1px' }}
                >
                  <FormattedHTMLMessage id="aboutStory2Text" />
                </Typography>
              </Grid>*/}
            </Grid>
          </TextContainer>
        </Grid>
      </Content>
    </Box>
  );
};

export default OurStory;
