import React from 'react';
import {
  Box,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import styled from 'styled-components';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from 'gatsby';

import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import Member from '../../components/Member';

import theme from '../../styles/themes';

import BackgroundImage from '../../assets/images/about-us-our-team.png';

const DimmedText = styled(Typography)`
  color: rgba(147, 162, 174, 0.2);
  font-weight: 900;
  transform: translateY(-50%);
  line-height: 117.19px;
  font-size: 60px;
  letter-spacing: 11.5px;

  ${theme.breakpoints.up('lg')} {
    font-size: 100px;
  }
`;

const Content = styled(ContentContainer)`
  ${theme.breakpoints.up('lg')} {
    padding: 60px 0;
  }
`;

const OurTeam = () => {
  const intl = useIntl(); // intl.locale
  const members = useStaticQuery(graphql`
    query Members {
      allStrapiMembers {
        nodes {
          strapiId
          name
          position
          image {
            url
          }
          alt
          locale
        }
      }
    }
  `);

  return (
    <Box>
      <ResponsiveImage style={{ backgroundImage: `url(${BackgroundImage})` }}>
        <Content>
          <Box position="relative">
            <Typography
              variant="h1"
              style={{
                zIndex: '1',
                fontWeight: '500',
                marginBottom: '10px',
                position: 'relative',
              }}
            >
              <FormattedHTMLMessage id="aboutTeamTitle" />
            </Typography>
            <Hidden smDown>
              <Box position="absolute" top="0" left="0">
                <DimmedText>
                  <FormattedHTMLMessage id="aboutTeamDrop" />
                </DimmedText>
              </Box>
            </Hidden>
          </Box>
          <Typography
            variant="body1"
            style={{
              maxWidth: '480px',
              lineHeight: '21.09px',
              marginBottom: '60px',
              position: 'relative',
            }}
          >
            <FormattedHTMLMessage id="aboutTeamText" />
          </Typography>
          <Grid
            container
            justify={
              useMediaQuery(theme.breakpoints.down('sm'), {
                defaultMatches: true,
              })
                ? 'center'
                : 'flex-start'
            }
            style={{
              width: 'calc(100% + 60px)',
              marginLeft: '-30px',
              marginRight: '-30px',
              marginTop: '-22.5px',
              marginBottom: '-22.5px',
            }}
          >
            {members?.allStrapiMembers.nodes
              .filter((item) => item.locale === intl.locale)
              .map((member: any) => (
                <Member
                  key={member.name + member.position + member.strapiId}
                  {...member}
                />
              ))}
          </Grid>
        </Content>
      </ResponsiveImage>
    </Box>
  );
};

export default OurTeam;
