import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import styled from 'styled-components';

import { IMemberProps } from '.';
import theme from '../../styles/themes';

const MemberBox = styled(Box)`
  padding: 22.5px 30px;
  width: 272px;
  ${theme.breakpoints.up('sm')} {
    width: 305px;
  }
  ${theme.breakpoints.up('md')} {
    width: 250px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 252px;
  }
`;

const Member: React.FC<IMemberProps> = ({ name, position, image, alt }) => {
  return (
    <MemberBox>
      <img
        src={image.url}
        alt={alt}
        style={{ borderRadius: '50%', marginBottom: '20px' }}
      />
      <Typography
        variant="h3"
        align="center"
        style={{
          lineHeight: '28.13px',
          letterSpacing: '1px',
          marginBottom: '10px',
        }}
      >
        {name}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        style={{ lineHeight: '21.09px', letterSpacing: '1px' }}
      >
        {position}
      </Typography>
    </MemberBox>
  );
};

export default Member;
